import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

const TitleDiv = styled.div`
  font-size: 1.25rem;
`
const ResourcesGridItems = ({ items = [] }) => {
  return (
    <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-12 gap-y-2 py-12 relative justify-center justify-items-center">
      {items.map((item, index) => {
        const image = getImage(item.logo)
        return (
          <div
            className="w-full h-96 relative flex flex-col portfolio-page-item"
            style={{ animationDelay: `${0.5 + index * 0.2}s` }}
            key={`resources-${item.id}-${index}`}
          >
            <div className="w-full h-1/2 flex items-center justify-center">
              <GatsbyImage
                image={image}
                alt={item.image_alt ? item.image_alt : `image ${index}`}
              />
              {/* <img
                src={item.logo}
                alt={item.image_alt}
                className="w-full object-cover"
              /> */}
            </div>
            <TitleDiv className="text-center w-full px-2 pt-4 text-xl font-medium">
              {item.title || <>&nbsp;</>}
            </TitleDiv>
            <a
              href={item.url}
              target="_blank"
              className="w-full text-center text-site-yellow text-sm pb-6"
            >
              View Website
            </a>
            <Link to={`tel:${item.contactNumber}`} className="text-center">
              {item.contactNumber}
            </Link>
          </div>
        )
      })}
    </div>
  )
}

export default ResourcesGridItems
