import React, { useEffect, useMemo, useState } from "react"
import Layout from "../../components/layout/layout"
import SEOnew from "../../components/seo-new"
import { graphql, useStaticQuery } from "gatsby"
import "./resources-page-styles.css"
import PageSelection from "../../components/common/PageSelection"
import CommitmentSignature from "../../components/sections/CommitmentSignature"
import ContactUsCompact from "../../components/sections/ContactUsCompact"
import ResourcesGridItems from "../../components/common/ResourcesGridItems"
import useWindowSize from "../../utils/useWindowSize"
import HeroSectionWithTitle from "../../components/common/HeroSectionWithTitle"
import { getImage } from "gatsby-plugin-image"

const ResourcesPage = () => {
  const screen = useWindowSize()
  const [gridView, setGridView] = useState(true)
  const [selectedResources, setSelectedResources] = useState([])
  const [selectedCategory, setSelectedCategory] = useState("")
  const [searchKey, setSearchKey] = useState("")

  const prismicResources = useStaticQuery(graphql`
    query ResourcesPageQuery {
      allPrismicBasePage(filter: { uid: { eq: "resources" } }) {
        edges {
          node {
            data {
              title
              hero_image {
                gatsbyImageData(width: 1000)
              }
            }
          }
        }
      }
      prismicResources {
        data {
          resources {
            contact_number
            link {
              url
            }
            title
            logo {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  const resources = useMemo(() => {
    if (prismicResources) {
      const data = prismicResources.prismicResources.data.resources.map(
        (resource, index) => {
          const { title, contact_number, link, logo } = resource

          return {
            id: index,
            title,
            contactNumber: contact_number,
            url: link.url,
            logo: logo,
          }
        }
      )

      return data
    }

    return []
  }, [prismicResources])

  const image = getImage(
    prismicResources.allPrismicBasePage?.edges[0].node.data.hero_image
  )

  return (
    <Layout>
      <SEOnew
        title={prismicResources.allPrismicBasePage?.edges[0]?.node.data.title}
      />
      <div className="w-full relative flex flex-col items-center justify-center">
        <HeroSectionWithTitle
          title={prismicResources.allPrismicBasePage?.edges[0]?.node.data.title}
          description={
            "Brion Jeannette Architecture seeks to provide resources to their clients that enhance their living experience – we are proud to share these specialists in their fields"
          }
          image={image}
          textLocation="left"
          imagePosition="center"
        />
      </div>
      <div className="flex w-full items-center justify-center flex-col pt-2 mb-12">
        <div className="w-full max-w-7xl px-4">
          <ResourcesGridItems items={resources} />
        </div>
      </div>
      <ContactUsCompact />
      <CommitmentSignature />
    </Layout>
  )
}

export default ResourcesPage
